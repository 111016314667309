<template>
	<div class="body">
		<Form inline :label-width="50" label-colon>
			<FormItem label="操作">
				<Input v-model="search.device_id" placeholder="请输入设备id"></Input>
			</FormItem>
			<FormItem :label-width="0">
				<Button type="primary" @click="init()">搜索</Button>
			</FormItem>
			<FormItem :label-width="0">
				<Button type="success" @click="edit_show()">添加</Button>
			</FormItem>
		</Form>
		<div class="table-list">
			<Table :loading="loading" :columns="columns" :data="data">
				<template slot="status" slot-scope="{row}">
					<Tag :color="row.status ? 'success' : 'default'" >{{ row.status ? '在线' : '离线' }}</Tag>
				</template>
				<template slot="operate" slot-scope="{row,index}">
					<Button style="margin-right: 5px;" @click="edit_show(row.id,index)">编辑</Button>
					<Button style="margin-right: 5px;" @click="check_device(row.id)">查看</Button>
					<Button @click="real_time_preview(row.id)">实时预览</Button>
				</template>
			</Table>
			<div class="page-r">
				<Page :total="total" :current="search.page" :page-size="search.page_size" :page-size-opts="[15,30,50]" show-total show-sizer />
			</div>
		</div>
		<Modal v-model="show" title="编辑" @on-cancel="edit_colse()">
			<Form :model="editData" :label-width="100" label-colon>
				<FormItem label="设备id">
					<Input v-model="editData.device_id" placeholder="请输入设备id(序列号)" :disabled="Boolean(editData.id)"></Input>
				</FormItem>
				<FormItem label="设备验证码">
					<Input v-model="editData.validate_code" placeholder="请输入设备验证码" :disabled="Boolean(editData.id)"></Input>
				</FormItem>
				<FormItem label="设备名称">
					<Input v-model="editData.device_name" placeholder="请输入设备名称"></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="text" @click="edit_colse()">取消</Button>
				<Button type="primary" @click="edit_submit()">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: 'MonitoringList',
		data () {
			return {
				loading: false, // 加载状态
				show: false, // 编辑显示
				total: 0, // 总条数
				search: {
					device_id: '', // 设备id
					device_name: '', // 设备名称
					status: 0, // 状态
					type_id: 0, // 类型
					device_type: 1, // 设备类型
					page_size: 15, // 每页条数
					page: 1 // 当前页码
				},
				columns: [
					{ title: 'id', key: 'id', align: 'center', maxWidth: 50 },
					{ title: '设备id', key: 'device_id', align: 'center' },
					{ title: '设备名称', key: 'device_name', align: 'center', minWidth: 100 },
					{ title: '状态', slot: 'status', align: 'center' },
					{ title: '创建时间', key: 'add_time', align: 'center', minWidth: 100 },
					{ title: '操作', slot: 'operate', align: 'center', minWidth: 150 }
				],
				data: [],
				editData: {
					id: 0, // id
					device_id: '', // 设备id
					validate_code: '', // 设备验证码
					device_name: '' // 设备名称
				}
			}
		},
		created () {
			this.init()
		},
		methods: {
			init(){
				let _this = this
				this.requestApi('/adm/get_monitoring_list', this.search ).then((res)=>{
					if(res.status){
						this.data = res.data.data
						this.total = res.data.total
					}else{
						_this.alertErr(res.msg)
					}
				})
			},
			// 编辑显示
			edit_show(id = 0, index = 0){
				this.show = true
				if(id){
					this.editData = JSON.parse(JSON.stringify(this.data[index]))
				}
			},
			// 取消 编辑页
			edit_colse(){
				this.show = false
				let data = {
					id: 0, // id
					device_id: '', // 设备id
					validate_code: '', // 设备验证码
					device_name: '' // 设备名称
				}
				this .editData = data
			},
			// 提交 编辑页
			edit_submit(){
				// 信息是否填写完整
				if(!this.editData.device_id)return this.alertErr('请输入设备id')
				if(!this.editData.validate_code)return this.alertErr('请输入设备验证码')
				if(!this.editData.device_name)return this.alertErr('请输入设备名称')
				// 发送请求
				let _this = this
				this.requestApi('/adm/edit_monitoring_info', this.editData ).then((res)=>{
					if(res.status){
						_this.init()
						_this.edit_colse()
					}else{
						_this.alertErr(res.msg)
					}
				})
			},
			// 查看基本信息
			check_device(id){
				this.toUrl('/monitoring/info', { id: id, action: 'first' })
			},
			// 实时预览
			real_time_preview(id){
				this.toUrl('/monitoring/info', { id: id, action: 'second' })
			}
		}
	}
</script>

<style scoped>
	.body {
		padding: 16px;
		background-color: #fff;
	}
	
	.search {
		background-color: rgb(248, 248, 249);
		padding: 5px;
		margin-bottom: 10px;
	}
	.page-r{
		padding-top: 16px;
		text-align: right;
	}
</style>
